import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { withErrorBoundary } from 'react-error-boundary';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from 'util/ProtectedRoute';
import ErrorView from 'util/ErrorView';
import LoadingView from 'app/routes/components/LoadingView';
import { ROLE_AUTHORITIES } from 'util/EvomConstants';

const setItemWithExpiration = (key, value, ttl) => {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getItemWithExpiration = key => {
  const itemString = localStorage.getItem(key);

  if (!itemString) {
    return null;
  }

  const item = JSON.parse(itemString);

  if (new Date().getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

const importComponent = componentLoaderFunction => new Promise(
  async (resolve, reject) => {
    try {
      resolve(await componentLoaderFunction());
    } catch (error) {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;

      if (error?.message && chunkFailedMessage.test(error.message)) {
        if (getItemWithExpiration('chunk_failed') === null) {
          setItemWithExpiration('chunk_failed', 'true', 10000);
          window.location.reload();
        } else {
          reject(error);
        }
      } else {
        reject(error);
      }
    }
  }
);

const buildLazyComponent = componentLoaderFunction => withErrorBoundary(
  lazy(() => importComponent(componentLoaderFunction)),
  { FallbackComponent: ErrorView }
);

const Home = buildLazyComponent(() => import('./Home'));
const CompanyDashboard = buildLazyComponent(() => import('./CompanyDashboard'));
const CompanyEmployees = buildLazyComponent(() => import('./CompanyEmployees'));
const EmployeesGenerateTokensPage = buildLazyComponent(() => import('./CompanyEmployeesGenerateTokens'));
const CompanyInvoices = buildLazyComponent(() => import('./CompanyInvoices'));
const CompanyStatement = buildLazyComponent(() => import('./CompanyStatement'));
const CompanyInvoiceCreationAndUpdatingPage = buildLazyComponent(() => import('./CompanyInvoiceCreationAndUpdating'));
const InvoiceDetail = buildLazyComponent(() => import('./InvoiceDetail'));
const CompanyBalanceInvoiceCreationAndUpdatingPage = buildLazyComponent(() => import('./CompanyBalanceInvoiceCreationAndUpdating'));
const POSDashboard = buildLazyComponent(() => import('./POSDashboard'));
const PaymentArrangementRealTimeDashboard = buildLazyComponent(() => import('./PaymentArrangementRealTimeDashboard'));
const PaymentArrangementTransactionsDashboard = buildLazyComponent(() => import('./PaymentArrangementTransactionsDashboard'));
const PaymentArrangementEnrollmentsDashboard = buildLazyComponent(() => import('./PaymentArrangementEnrollmentsDashboard'));
const CreditAndConsumptionDashboard = buildLazyComponent(() => import('./CreditAndConsumptionDashboard'));
const FinancialTransactionsDashboard = buildLazyComponent(() => import('./FinancialTransactionsDashboard'));
const PaymentArrangementInvoices = buildLazyComponent(() => import('./PaymentArrangementInvoices'));
const Companies = buildLazyComponent(() => import('./Companies'));
const Credits = buildLazyComponent(() => import('./Credits'));
const Expenses = buildLazyComponent(() => import('./Expenses'));
const WalletParameterization = buildLazyComponent(() => import('./WalletParameterization'));
const WalletRequests = buildLazyComponent(() => import('./WalletRequests'));
const WalletRequestsHistory = buildLazyComponent(() => import('./WalletRequestsHistory'));
const EvomAccountWallets = buildLazyComponent(() => import('./EvomAccountWallets'));
const CompanyUserEvomAccountDetails = buildLazyComponent(() => import('./CompanyUserEvomAccountDetails'));
const PaymentArrangementEvomAccountDetails = buildLazyComponent(() => import('./PaymentArrangementEvomAccountDetails'));
const PosUsers = buildLazyComponent(() => import('./PosUsers'));
const PosAgency = buildLazyComponent(() => import('./PosAgency'));
const PosTransactions = buildLazyComponent(() => import('./PosTransactions'));
const PosAgencyUsers = buildLazyComponent(() => import('./PosAgencyUsers'));
const CreditReport = buildLazyComponent(() => import('./CreditReport'));
const ExpensesReport = buildLazyComponent(() => import('./ExpensesReport'));
const POSSalesByDayReport = buildLazyComponent(() => import('./POSSalesByDayReport'));
const POSSalesByOperatorReport = buildLazyComponent(() => import('./POSSalesByOperatorReport'));
const ReportTemplate = buildLazyComponent(() => import('./ReportTemplate'));
const ReportExecutionDetails = buildLazyComponent(() => import('./ReportExecutionDetails'));
const Users = buildLazyComponent(() => import('./Users'));
const Exports = buildLazyComponent(() => import('./Exports'));
const PromotionsCashIn = buildLazyComponent(() => import('./PromotionsCashIn'));
const PromotionsCashOut = buildLazyComponent(() => import('./PromotionsCashOut'));
const CompanyEmployeeGroups = buildLazyComponent(() => import('./CompanyEmployeeGroups'));
const Page404 = buildLazyComponent(() => import('./Page404'));

const Routes = () => {
  const canManageWalletApplications = useSelector(({ auth }) => auth?.authUser?.paymentArrangementCanManageWalletApplications);

  return (
    <Suspense
      fallback={<LoadingView/>}
    >
      <Switch>
        <ProtectedRoute
          allowedRoleAuthorities={[
            ROLE_AUTHORITIES.ROLE_COMPANY_USER,
            ROLE_AUTHORITIES.ROLE_EMPLOYER_USER,
            ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN,
            ROLE_AUTHORITIES.ROLE_POS_AGENCY
          ]}
          path="/home"
          component={Home}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_COMPANY_USER, ROLE_AUTHORITIES.ROLE_EMPLOYER_USER]}
          requiredPermissionAuthority="PERM_BO_COMPANY_DASHBOARD"
          path="/company-dashboard"
          component={CompanyDashboard}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_COMPANY_USER, ROLE_AUTHORITIES.ROLE_EMPLOYER_USER]}
          requiredPermissionAuthority="PERM_BO_COMPANY_EMPLOYEES"
          path="/company-employees"
          component={CompanyEmployees}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_COMPANY_USER, ROLE_AUTHORITIES.ROLE_EMPLOYER_USER]}
          requiredPermissionAuthority="PERM_BO_COMPANY_EMPLOYEES"
          path="/company-employees-tokens-generation"
          component={EmployeesGenerateTokensPage}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[
            ROLE_AUTHORITIES.ROLE_COMPANY_USER,
            ROLE_AUTHORITIES.ROLE_EMPLOYER_USER
          ]}
          requiredPermissionAuthority="PERM_BO_COMPANY_EMPLOYEES"
          path="/company-employees-groups"
          component={CompanyEmployeeGroups}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[
            ROLE_AUTHORITIES.ROLE_COMPANY_USER,
            ROLE_AUTHORITIES.ROLE_EMPLOYER_USER,
            ROLE_AUTHORITIES.ROLE_POS_AGENCY
          ]}
          requiredPermissionAuthority="PERM_BO_COMPANY_INVOICES"
          path="/company-invoices"
          component={CompanyInvoices}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[
            ROLE_AUTHORITIES.ROLE_COMPANY_USER,
            ROLE_AUTHORITIES.ROLE_EMPLOYER_USER,
            ROLE_AUTHORITIES.ROLE_POS_AGENCY
          ]}
          requiredPermissionAuthority="PERM_BO_COMPANY_STATEMENT"
          path="/company-statement"
          component={CompanyStatement}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_COMPANY_USER, ROLE_AUTHORITIES.ROLE_EMPLOYER_USER]}
          requiredPermissionAuthority="PERM_BO_COMPANY_INVOICES"
          path="/company-invoice-creation"
          component={CompanyInvoiceCreationAndUpdatingPage}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_COMPANY_USER, ROLE_AUTHORITIES.ROLE_EMPLOYER_USER]}
          requiredPermissionAuthority="PERM_BO_COMPANY_INVOICES"
          path="/company-invoice-updating/:invoiceUuid"
          component={CompanyInvoiceCreationAndUpdatingPage}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[
            ROLE_AUTHORITIES.ROLE_COMPANY_USER,
            ROLE_AUTHORITIES.ROLE_EMPLOYER_USER,
            ROLE_AUTHORITIES.ROLE_POS_AGENCY
          ]}
          requiredPermissionAuthority="PERM_BO_COMPANY_INVOICES"
          path="/invoice/:invoiceUuid"
          component={InvoiceDetail}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[
            ROLE_AUTHORITIES.ROLE_COMPANY_USER,
            ROLE_AUTHORITIES.ROLE_EMPLOYER_USER,
            ROLE_AUTHORITIES.ROLE_POS_AGENCY
          ]}
          requiredPermissionAuthority="PERM_BO_COMPANY_INVOICES"
          path="/company-balance-invoice-creation"
          component={CompanyBalanceInvoiceCreationAndUpdatingPage}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[
            ROLE_AUTHORITIES.ROLE_COMPANY_USER,
            ROLE_AUTHORITIES.ROLE_EMPLOYER_USER,
            ROLE_AUTHORITIES.ROLE_POS_AGENCY
          ]}
          requiredPermissionAuthority={["PERM_BO_COMPANY_INVOICES", "PERM_BO_COMPANY_INVOICES"]}
          path="/company-balance-invoice-updating/:invoiceUuid"
          component={CompanyBalanceInvoiceCreationAndUpdatingPage}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_POS_AGENCY]}
          requiredPermissionAuthority="PERM_BO_POS_DASHBOARD"
          path="/pos-agency-dashboard"
          component={POSDashboard}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_REAL_TIME_DASHBOARD"
          path="/payment-arrangement-real-time-dashboard"
          component={PaymentArrangementRealTimeDashboard}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_TRANSACTION_DASHBOARD"
          path="/payment-arrangement-transactions-dashboard"
          component={PaymentArrangementTransactionsDashboard}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_ENROLLMENTS_DASHBOARD"
          path="/payment-arrangement-enrollments-dashboard"
          component={PaymentArrangementEnrollmentsDashboard}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_CREDIT_AND_CONSUMPTION_DASHBOARD"
          path="/credit-and-consumption-dashboard"
          component={CreditAndConsumptionDashboard}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_FINANCIAL_TRANSACTIONS_DASHBOARD"
          path="/financial-transactions"
          component={FinancialTransactionsDashboard}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_PAYMENT_ARRANGEMENT_INVOICES"
          path="/payment-arrangement-invoices"
          component={PaymentArrangementInvoices}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_COMPANIES"
          path="/companies"
          component={Companies}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_CREDITS"
          path="/credits"
          component={Credits}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_EXPENSES"
          path="/expenses"
          component={Expenses}
          exact
        />

        {
          canManageWalletApplications &&
          <ProtectedRoute
            allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
            requiredPermissionAuthority="PERM_BO_PMTARG_WALLET_PARAMETERIZATION"
            path="/wallet-parameterization"
            component={WalletParameterization}
            exact
          />
        }

        {
          canManageWalletApplications &&
          <ProtectedRoute
            allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
            requiredPermissionAuthority="PERM_BO_PMTARG_WALLET_REQUESTS"
            path="/wallet-requests"
            component={WalletRequests}
            exact
          />
        }

        {
          canManageWalletApplications &&
          <ProtectedRoute
            allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
            requiredPermissionAuthority="PERM_BO_PMTARG_WALLET_REQUESTS"
            path="/wallet-requests-history"
            component={WalletRequestsHistory}
            exact
          />
        }

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_EVOM_ACCOUNT"
          path="/evom-account-wallets"
          component={EvomAccountWallets}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_POS_USERS"
          path="/pos-users"
          component={PosUsers}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_CREDIT_REPORT"
          path="/credit-report"
          component={CreditReport}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_EXPENSES_REPORT"
          path="/expenses-report"
          component={ExpensesReport}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_POS_REPORTS"
          path="/pos-sales-by-day-report"
          component={POSSalesByDayReport}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_POS_REPORTS"
          path="/pos-sales-by-operator-report"
          component={POSSalesByOperatorReport}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_REPORT_TEMPLATE"
          path="/outros-relatorios"
          component={ReportTemplate}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_REPORT_TEMPLATE"
          path="/relatorio/:reportExecutionUuid"
          component={ReportExecutionDetails}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_USERS"
          path="/users"
          component={Users}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_POS_AGENCY"
          path="/pos-agency"
          component={PosAgency}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_POS_TRANSACTIONS"
          path="/pos-transactions"
          component={PosTransactions}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_POS_AGENCY_USERS"
          path="/agency-users"
          component={PosAgencyUsers}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_EXPORTS"
          path="/exportacoes"
          component={Exports}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_COMPANY_USER, ROLE_AUTHORITIES.ROLE_EMPLOYER_USER]}
          requiredPermissionAuthority="PERM_BO_COMPANY_EMPLOYEES"
          path="/evom-account"
          component={CompanyUserEvomAccountDetails}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_EVOM_ACCOUNT"
          path="/payment-arrangement/evom-account"
          component={PaymentArrangementEvomAccountDetails}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_PROMOTIONS_CASH_IN"
          path="/promotions-cash-in"
          component={PromotionsCashIn}
          exact
        />

        <ProtectedRoute
          allowedRoleAuthorities={[ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN]}
          requiredPermissionAuthority="PERM_BO_PMTARG_PROMOTIONS_CASH_OUT"
          path="/promotions-cash-out"
          component={PromotionsCashOut}
          exact
        />

        <Route component={Page404}/>
      </Switch>
    </Suspense>
  );
};

export default Routes;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleCollapsedNav } from 'actions/Setting';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import { AppBar, Toolbar, IconButton, useTheme } from '@material-ui/core';
import PaymentArrangementController from 'containers/AppLayout/Vertical/Header/PaymentArrangementController';
import DiscountPolicy from 'containers/AppLayout/Vertical/Header/DiscountPolicy';
import ActiveEmployerController from 'containers/AppLayout/Vertical/Header/ActiveEmployerController';
import CompanyBalance from './CompanyBalance';
import BalanceCreditLimitAmount from './BalanceCreditLimitAmount';
import VerticalHeaderInfoItem from 'app/routes/components/VerticalHeaderInfoItem';
import EvomUtils from 'util/EvomUtils';

const Index = () => {
  const dispatch = useDispatch();
  const { drawerType, navCollapsed } = useSelector(({ settings }) => settings);
  const { authUser, activeCompanyData } = useSelector(({ auth }) => auth);
  const primaryColor = useTheme().palette.primary.main;

  const onToggleCollapsedNav = () => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const drawerStyle = drawerType === FIXED_DRAWER ? 'd-block d-xl-none' : drawerType === COLLAPSED_DRAWER ? 'd-block' : 'd-none';

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false} style={{ backgroundColor: primaryColor }}>
        <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu" onClick={onToggleCollapsedNav}>
          <span className="menu-icon"/>
        </IconButton>

        <PaymentArrangementController/>

        <ul className="header-notifications list-inline ml-auto">
          {
            authUser?.isCompanyOrEmployerUser &&
            <li
              className="list-inline-item"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}
            >
              <CompanyBalance/>
              <DiscountPolicy/>
              <ActiveEmployerController/>
            </li>
          }

          {
            authUser?.isPOSAgencyUser &&
            <li
              className="list-inline-item"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}
            >
              <VerticalHeaderInfoItem 
                caption="Tipo de recarga"
                info={EvomUtils.formatRechargeBalanceType(activeCompanyData?.rechargeBalanceType)}
              />
              <BalanceCreditLimitAmount />
              <CompanyBalance/>
              <ActiveEmployerController/>
            </li>
          }
        </ul>

        <div className="ellipse-shape"/>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
